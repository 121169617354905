body {
    font-size: 18px;
    line-height: 1.72222;
    min-width: 320px;
    padding-top: 70px;
    color: @wet-asphalt;
}

a {
    font-weight: 700;

    [class*="tbicon"] {
        display: inline-block;
    }
}

.allow-break-word {
    word-wrap: break-word;
}

.os24 {
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
}

.bg-blue {
    background-color: @bg-blue;
}

.bg-blue-lt {
    background-color: @bg-blue-lt;
}

.bg-green {
    background-color: @bg-green;
}

.bg-green-lt {
    background-color: @bg-green-lt;
}

.number-highlight {
    background-color: @bg-number-highlight !important;
}

.number-highlight-lt {
    background-color: @bg-number-highlight-lt !important;
}

.text-orange {
    color: @box-orange;
}

.ul-bullet {
    list-style: none;
    padding: 0;

    li {
        padding-left: 2.5rem;
        padding-bottom: 1rem;

        line-height: 1.3em;

        &:before {
            display: inline-block;
            vertical-align: top;
            font-family: tbfont;
            .set-glyph-content(play);
            margin-left: -2.5rem;
            width: 2.5rem;
        }
    }
}

.btn-help {
    font-family: tbfont;
    font-weight: normal;
    font-size: 85%;
    .set-glyph(question-circle);
    text-decoration: none !important;

    .access-table th & {
        vertical-align: top;
        color: inherit;
        font-size: 75%;
    }
}

.phone-number {
    white-space: nowrap;
}

.modal-backdrop {
    opacity: 0.5;
}

.lcm-icon {
    font-size: 30px;
    color: #205493;
    vertical-align: middle;
}

.modal-body button.close {
    position : absolute;
    right : 10px;
    top : 4px;
}

.content {
    min-height: 440px;
    margin-bottom : 20px;
}

.mark {
  font-size:150%;
  background:none;
  position:relative;
  bottom:13px;
}

.content h2 {
    font-size: 24px;
}
.subhead {
  font-family: 'Oswald', sans-serif;
  font-size: 28px;
  font-style: italic;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 20px;
}

.call, .signupCall {
    color: #000;

    @media (min-width: @screen-md-min) {
        &.light {
            color: #fff;

            a:not(.btn) {
                color: @logo-green;
            }
        }
    }
}

.call {
    @media (min-width: @screen-md-min) {
        position : absolute;
        top : 20px;
        right : 20px;

        &.indent {
            right : 40px;
        }
    }
}

.content-home {
    .home {
        font-size: 22px;
        @media (min-width: @screen-lg-min) {
            font-size: 24px;
        }

        h1 {
            margin: 0;

            &.subheading {
                font-weight: 300;
                font-size: 24px;

                @media (min-width: @screen-md-min) {
                    font-size: 34px;
                }

                @media (min-width: @screen-lg-min) {
                    margin-top: 17px;
                    font-size: 44px;
                }
            }
        }
    }

    @media (max-width: @screen-sm-max) {
        &.index {
            background-image: none !important;
        }
    }

    @media (min-width: @screen-md-min) {
        position : relative;
        margin-bottom: 20px;

        h1 {
            font-family: 'Oswald', sans-serif;
            font-weight: 700;
        }

        .row {
            height : 440px;
        }

        .signupCall, .call {
            position : absolute;
            top : 20px;
            right : 40px;
            z-index : 2;

            .btn {
                position : absolute;
                right : 5px;
                top : 30px;
            }
        }

        &.index {
            border:1px solid gray;

            h1 {
                font-size: 61px;
            }

            .home-logo {
                position: absolute;
                left:35px;
                bottom : 19px;
            }

            .home {
                margin-top: 40px;
                margin-left: 20px;

                ul {
                    margin-top: 20px;
                    margin-bottom: 0;

                    @media (min-width: @screen-lg-min) {
                        margin-left: 60px;
                    }
                }
            }
        }

        &.trial {
            border: 1px solid gray;
            background: #fff url('../../images/crayons2.jpg') no-repeat bottom right;

            .home {
                padding: 20px;

                h1 {
                    font-size: 50px;
                }

                .form-group {
                    width: 50%;
                }
            }
        }
    }
}

@media (min-width: @screen-md-min) {
    .features-col2 {
        min-height : 699px;
    }

    .contact-form {
        border-left:1px solid gray;
    }
}

.content.prayer-line {
    .signup-buttons {
        .btn {
            margin-top : 10px;
            white-space : normal;
        }
    }
}

.customers-text {
    .stripe {
        background-color : #edeff1;
    }

    p {
        padding-top : 20px;
        padding-bottom : 20px;
        text-align : center;
        margin : 0;
    }

    img {
        margin : 0 auto;
    }

    .cquote {
        font-family:'Magra','Lato',sans-serif;
        font-weight:700;
        font-size:20px;
        font-style:italic;
    }

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .row1 .cquote {
            margin-top : 26px;
        }

        .row2 img {
            margin-top : 40px;
        }

        .row3 .cquote {
            margin-top : 10px;
        }

        .row4 img {
            margin-top : 15px;
        }

        .row5 .cquote {
            margin-top : 10px;
        }

        .row6 .cquote {
            margin-top : 15px;
        }

        .row8 .cquote {
            margin-top : 30px;
        }

        .row9 .cquote {
            margin-top : 13px;
        }

        .row10 img {
        }

        .row11 img {
            margin-top : 20px;
        }

        .row12 .cquote {
            margin-top : 20px;
        }
    }

    @media (min-width: @screen-lg-min) {
        .row1 .cquote {
            margin-top : 40px;
        }

        .row2 img {
            margin-top : 20px;
        }

        .row3 .cquote {
            margin-top : 25px;
        }

        .row4 .cquote {
            margin-top : 10px;
        }

        .row5 .cquote {
            margin-top : 25px;
        }

        .row6 .cquote {
            margin-top : 20px;
        }

        .row7 .cquote {
            margin-top : 10px;
        }

        .row8 .cquote {
            margin-top : 40px;
        }

        .row9 .cquote {
            margin-top : 30px;
        }

        .row11 img {
            margin-top : 10px;
        }

        .row12 .cquote {
            margin-top : 20px;
        }
    }
}

.tiles {
    .index-tile(@bg-color) {
        @bg-color-light: lighten(@bg-color, 2.5%);
        background: @bg-color;
        border: 1px solid @bg-color;
        transition: box-shadow .12s linear, background-color .12s linear, transform .12s linear;

        &:hover, &:focus {
            background: lighten(@bg-color, 2.5%);
            transform : translate(-1px, -1px);
            .box-shadow(~"inset 1px 1px 1px 1px rgba(0,0,0,.075), 1px 1px 8px @{bg-color-light}");
            outline: none;
        }

        .tile-title {
            text-shadow: 2px 2px darken(@bg-color, 15%);
        }
    }

    a {
        display: block;
        position: relative;
        border-radius: 6px;
        margin-bottom: 20px;
        padding: 14px;

        color: white;
        text-decoration: none !important;

        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            min-height: 205px;
        }

        @media (min-width: @screen-lg-min) {
            min-height:185px;
        }

        .tile-title {
            margin-bottom: 15px;
            font-family: 'Oswald', sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.1;
            text-align: center;

            @media (min-width: @screen-md-min) {
                min-height : 55px;
            }
        }

        p {
            line-height: 1;
            font-weight: 500;
        }

        .tile-hot-ribbon {
            display: block;
            position: absolute;
            right: -4px;
            top: -4px;
            width: 82px;
        }

        &.tile-red {
            .index-tile(@bg-red);
        }
        &.tile-blue {
            .index-tile(@bg-blue);
        }
        &.tile-green {
            .index-tile(@bg-green);
        }
        &.tile-primary {
            .index-tile(@bg-primary);
        }
    }
}

.index-middle {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .gutter-8();

        .tiles a {
            margin-bottom: 8px;
        }
    }

    .promos {
        border: solid 1px gray;
        padding: 10px;
        margin-bottom: 20px;

        table {
            height: 100%;
            width: 100%;

            p {
                margin: 0;
            }

            .more {
                margin-top: 5px;
                font-family: 'Oswald', sans-serif;
                font-style: italic;
                font-weight: 300;
                text-align: right;
            }

            @media (min-width : @screen-md-min) {
                td:first-child {
                    vertical-align: middle;
                    width: 200px;
                }

                td:last-child {
                    p {
                        line-height:20px;
                    }
                }
            }
        }
    }
}

footer {
    font-size: 15px;
    background-color: @bg-grey;
    color: @wet-asphalt;

    @media print {
        background-color : #fff;
    }

    .footer-links {
        @footer-link-color: lighten(@wet-asphalt, 15%);
        font-size: 13px;
        line-height: 14px;

        .col-xs-6 {
            @media print {
                display : none;
            }
        }

        .footer-copyright {
            border-top: 1px solid @footer-link-color;
            text-align:right;
            color: @footer-link-color;
            margin: 0 15px;
            padding: 5px 0;

            a {
                padding-right: 10px;
            }

            @media print {
                border-width: 0;
            }
        }

        h2 {
            font-family : @font-family-base;
            font-size: 16px;
        }

        ul {
            .list-unstyled();
            margin: 0 0 16px;

            li {
                padding: 4px 0;
            }
        }

        a {
            color: @footer-link-color;
            font-weight: 400;

            &:hover {
                color: @wet-asphalt;
            }
        }
    }

    .footer-turbo {
        padding: 20px 30px 30px;
        color: @bg-grey;
        background-color: @wet-asphalt;
        line-height: 1.3;

        .logo-horizontal {
            width: 200px;
            height: 30px;
            background-position: top;
        }

        .footer-turbo-questions {
            margin: 2.5rem 0;
            padding: 0.5rem;
            border-top: 1px solid #1bc5a3;
            border-bottom: 1px solid #1bc5a3;
            text-align: center;

            a {
                margin-left: 0.75rem;
                color:#fff;

                &:hover {
                    color: @bg-grey;
                }
            }
        }

        @media (max-width: @screen-xs-max) {
            .btn-primary {
                margin-top: 2rem;
            }
            .row {
                text-align : center;
            }
        }

        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            min-height: 390px;
        }
    }
}

.more-info-container {
    .more-info {
        margin-bottom: 20px;
    }
}

.more-info {
    &.more-info-blue {
        .more-info-title {
            background-color : @bg-blue;
        }
    }

    &.more-info-success {
        .more-info-title {
            background-color : @bg-green;
        }
    }

    &.more-info-primary {
        .more-info-title {
            background-color : @bg-primary;
        }
    }

    &.more-info-red {
        .more-info-title {
            background-color : @bg-red;
        }
    }

    &.more-info-green {
        .more-info-title {
            background-color : @bg-green;
        }
    }

    .more-info-title {
        font-size:24px;
        text-align:center;
        font-family: 'Oswald', sans-serif;
        font-weight:300;
        color:white;
    }

    .more-info-content {
        background-color: @bg-grey;
        border: 1px solid #ccc;
        margin: 0 auto 0 auto;
        padding: 10px;
    }
}

.plans-table, .settings-table {
    width : 100%;
    border-style : hidden;

    @media print {
        & {
            border-bottom : 1px solid black !important;
            border-top : 1px solid black !important;
            border-left : 1px solid black !important;
            border-right : 1px solid black !important;
        }

        td, th {
            border-top-width : 1px !important;
            border-left-width : 1px !important;
            border-right-width : 1px !important;
            border-bottom-width : 1px !important;
            border-color : black !important;
        }
    }

    td, th {
        border : 5px solid white;
    }

    th {
        text-align:center;
        padding-top : 10px;
        padding-bottom : 10px;
        font-family: 'Oswald', sans-serif;
        font-weight:400;
        color:white;
    }

    td {
        height:60px;
        text-align:center;
        line-height:24px;
        color:black;
    }

    strong {
        font-weight:bolder;
        font-size:larger;
    }

    a.btn-block {
        padding-top : 9px;
        padding-bottom : 9px;
    }
}

.plans-table {
    th {
        font-size: 24px;
    }
}

.settings-table {
    th {
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: left;

        &:nth-child(odd) {
            background-color : @bg-green;
        }

        &:nth-child(even) {
            background-color : @bg-blue;
        }
    }

    td {
        height: 40px;
        padding-left:18px;
        font-size: 16px;
        text-align: left;

        &:nth-child(odd) {
            background-color : @bg-green-lt;
        }

        &:nth-child(even) {
            background-color : @bg-blue-lt;
        }
    }
}

.premium-info {
    .more-info-content {
        padding : 0;
    }

    table {
        width : 100%;

        tr:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }

        td {
            padding : 8px 14px;
            line-height : 24px;

            &:nth-child(2) {
                text-align : center;
            }
        }

        tr:first-child td {
            padding-top : 3px;
            padding-bottom : 3px;
        }

        .table-subheading {
            font-family: 'Oswald', sans-serif;
            font-size: 16px;
        }
    }
}

.box-lead {
  padding:10px;
  line-height:24px;
}

.plans-title {
  font-size:22px;
}

.mobile-title {
    border-left: 0 !important;
    background-color: @bg-grey;
}

.highlight-box {
    border: 3px solid @box-orange;
    border-radius: 10px;
    margin : 0 auto;
    padding : 5px;

    @media (max-width : @screen-md-max) {
        width : 70%;
    }

    @media (min-width : @screen-lg-min) {
        width : 60%;
    }

    @media (max-width : @screen-xs-max) {
        width : 100%;
    }

    table {
        width : 100%;
    }

    .icon {
        width : 60px;
        border-radius : 5px;
        padding:8px;
        line-height : 24px;
        background: @box-orange;
        font-family: 'Oswald', sans-serif;
        text-align:center;
        color:white;
    }

    .col2 {
        padding-left : 10px;
        padding-right : 15px;
    }

    .wrap {
        margin-top : 10px;
    }
}

.item-list li {
    padding-bottom:15px;
}

.access-table {
    border-spacing:5px;
    border-collapse:separate;
    padding:5px;
    vertical-align:middle;

    th {
        font-size:24px;
        text-align:center;
        line-height:60px;
        font-family: 'Oswald', sans-serif;
        font-weight:400;
        color:white;

        &.col1 {
            background-color : @bg-green;
        }

        &.col2 {
            background-color : @bg-blue;
        }
    }
    td {
        height:40px;
        text-align:center;
        line-height:24px;

        &.col1 {
            background-color : @bg-green-lt;
        }

        &.col2 {
            background-color : @bg-blue-lt;
        }
    }
}

.access-title {
    font-family: 'Oswald', sans-serif;
    font-size:22px;
    color: #000;
}

@media (max-width: @screen-xs-max) {
    .number-list {
        .gutter-8();
    }
}
.phone-number-table {
    table-layout:fixed;
    border: none;
    width: 100%;
    text-align:left;

    th {
        font-weight:bold;
        padding-bottom:12px;
        padding-top:18px;
    }

    td {
        vertical-align: top;
        font-size:16px;
        padding-bottom:0px;
        height:20px;

        &:first-child {
            padding-left:20px;
        }

        &:nth-child(2) {
            text-align : right;
            padding-right:20px;
        }
    }

    tr:nth-child(even) {
        background-color: @bg-row-highlight;
    }
}

.flag-size {
    height: 20px;
    border-width: 1px;
    border-color: gray;
    border-style: solid;
    margin-right: 9px;
}

.nice-table {
    border:1px solid @bg-blue;

    th, td {
        padding:8px;
    }

    th {
        background-color: @bg-blue;
        color: #fff;
    }

    tr:nth-child(odd) {
        background-color: @bg-blue-lt;
    }

    .highlight {
        font-family:'Oswald',sans-serif;
        font-weight: bold;
    }

    &.text-center {
        td, th {
            text-align: center !important;
        }
    }
}
.box-details {
    p {
        font-size:16px;
        line-height:20px;
    }

    h2 {
        font-family:'Oswald',sans-serif;
        font-size:20px;
        font-weight:400;
    }
}

@key-size-large: 45px;
@key-size-small: 30px;

.keys-blue, .keys-green {
    display: inline-block;
    border: 5px solid black;
    border-radius: 5px;
    width: @key-size-large;
    height: @key-size-large;
    text-align:center;
    vertical-align:middle;
    font-family:'tbfont';
    font-size:32px;
    margin-right:5px;
    line-height:1;

    @media (max-width: @screen-sm-max) {
        border-width : 2px;
        width : @key-size-small;
        height : @key-size-small;
        font-size: 24px;
        margin-right: 3px;
    }
}
.keys-blue {
  border-color: @brand-primary;
  color:@brand-primary;
}
.keys-green {
  border-color: @green-link-color;
  color: @green-link-color;
}

.keypad {
    width: 100%;
    table-layout: fixed;

    &.w-auto td {
        width: auto !important;
    }

    td {
        padding-bottom:5px;

        &:nth-last-child(2) {
            // don't let keys wrap
            white-space: nowrap;
            width: ((@key-size-large + 5) * 3);

            @media (max-width: @screen-sm-max) {
                width: ((@key-size-small + 3) * 3);
            }
        }

        // description col
        &:last-child {
            padding-left: 10px;
        }
    }

    .td-mode {
        padding: 0;
        border-style: solid;
        border-width: 2px 0 2px 2px;
        border-color: @green-link-color;
        color: @green-link-color;
        transform: rotate(-90deg);
    }

    &.keypad-mode {
        .hidden-xs();
        .hidden-sm();

        td:first-child {
            width: 50px;
        }
    }
}

.accordian-indent {
  margin:20px;
}

// dialout
.dialout {
    h1:first-child {
        margin-top : 20px;
    }

    .tbicon-make-call {
        padding-right : 10px;
        font-size : 40px;
    }
}
// qa_session
.qa_session {
    .tbicon-hand {
        font-size : 80px;
        padding-right : 20px;
    }
}
// help.tpl
.help {
    .more-info {
        .more-info-content {
            padding : 5px 15px;
            margin-bottom : 20px;
            background-color : #fff !important;

            ul li {
                margin-bottom: 10px;
                line-height: 24px;

                a {
                    color: @wet-asphalt;
                    font-weight: normal;
                }
            }

            @media (min-width: @screen-md-min) {
                min-height : 160px;
            }
        }
    }

    p {
        margin: 0;
    }
}

.contact-form {
    height : 630px;

    .request-sent {
        margin-top : 310px;
    }
}

.contact-info {
    @media (min-width: @screen-md-min) {
        dt {
            width : 120px;
        }

        dd {
            margin-left : 140px;
        }
    }
}

@media print {
    .print-expand .collapse {
        display : block;
        visibility : visible;
        height: auto !important;
    }
}

.accordian-indent {
    .panel-title {
        font-size : 16px;
    }

    .panel-group-blue-lt .panel-heading {
        background-color : @bg-blue-lt;
    }

    .panel-group-green-lt .panel-heading {
        background-color : @bg-green-lt;
    }
}

.how_it_works {
    .highlight-green {
        border-radius: 10px;
        border:solid 4px @green-link-color;
        padding:10px;

        h1 {
            color : @green-link-color;
            margin-top : 10px;
            text-align:center;
        }
    }

    .steps {
        margin-top: 20px;
        td {
            padding-bottom: 30px;
        }
        .step {
            padding-right : 25px;
            font-family:'Oswald';
            font-size:80px;
            font-weight:700;
            vertical-align : top;
            line-height: 1;

            &.step_1 {
                color: @bg-red;
                text-shadow: 2px 2px darken(@bg-red, 15%);
            }

            &.step_2 {
                color: @bg-primary !important;
                text-shadow: 2px 2px darken(@bg-primary, 15%);

                a {
                    color: inherit !important;
                    text-decoration: none !important;
                }
            }

            &.step_3 {
                color: @bg-green;
                text-shadow: 2px 2px darken(@bg-green, 15%);
            }
        }

        h1 {
            margin-top: 0.25rem;
        }
    }
}

.cmd {
    font-family: 'tbfont',sans-serif;
    font-style: normal;
}

.shopperseal {
    position : absolute;
    bottom : -1px;
    right : -1px;
    display : block;

    width : 170px;
    height : 60px;

    border-color : white;
    border-style : solid;
    border-width : 4px 0px 0px 4px;

    background-color : white;

    a {
        border : 0 !important;
    }
}

.caret {
    margin-left: 5px;
    border-top:   8px dashed;
    border-top:   8px solid ~"\9"; // IE8
    border-right: 6px solid transparent;
    border-left:  6px solid transparent;
}
