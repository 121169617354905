@import "tbfont-glyphmap";

@font-face {
	font-family: 'tbfont';
	src: data-uri('font/woff;base64', '../fonts/tbfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

.tbicon() {
    font-family: 'tbfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height : 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.set-glyph(@name) {
    &:before {
        .kvp(@tbfontglyphmap, @name);
        content: @kvp;
    }
}

.set-glyph-content(@name) {
    .kvp(@tbfontglyphmap, @name);
    content: @kvp;
}

[class^="tbicon"],
[class*="tbicon"] {
    .tbicon();
}

.createglyphmap(@iterator:1) when(@iterator <= length(@tbfontglyphmap)) {
    @name: extract(extract(@tbfontglyphmap, @iterator),1);

    &@{name}:before {
        content: extract(extract(@tbfontglyphmap, @iterator),2);
    }
    .createglyphmap((@iterator + 1));
}
.tbicon-{
    .createglyphmap();
}

.font-preload {
    height : 0;
    width : 0;
}
