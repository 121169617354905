h1, h2, h3 {
    margin-top : 30px;
    margin-bottom : 15px;
}

h1 {
    font-family: 'Magra', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: @brand-primary;
}

h2 {
    font-family: 'Magra', Helvetica, Arial, sans-serif;
}

h3 {
    color:@brand-primary;
}

p {
    margin-bottom : 15px;
}

ul, ol {
    margin-bottom : 15px;
}

hr {
    border-top:1px solid #ccc;
    border-bottom:none;
    margin-top:20px;
}

.magra {
    font-family:'Magra','Lato',sans-serif;
}

.magra-strong {
    font-weight:bold;
    color:@brand-primary;
    font-family:'Magra','Lato',sans-serif;
}
