@import "fonts/tbfont";
@import "fonts/lato";
@import "fonts/oswald";
@import "fonts/magra";

.logo-horizontal {
    background-image: data-uri('image/svg+xml', '../images/logo-light.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
}

@import "bootstrap/bootstrap.less";

@import "tb-form-control-focus";

@import "type";
@import "forms";

@import "utility";

// @logo-green is brand-success in web-common variables
@logo-green  : #2ecc73;

@bg-red      : #b71540;
@bg-blue     : #184f76;
@bg-blue-lt  : #d7e7f2;
@bg-green    : #079992;
@bg-green-lt : #e1f2eb;
@bg-grey     : #f1f1f3;
@bg-primary  : @brand-primary;

@bg-row-highlight: @color-primary-alt-lightest; // #e1f3ff;
@bg-number-highlight: @color-secondary-lightest; // #f9dede;
@bg-number-highlight-lt: #fff5f5;

@import "bootstrap/tb-button-variant";

@color-btn-primary-fg: #fff;
@color-btn-primary-bg: @btn-primary-bg;
@color-btn-primary-border: @btn-primary-border;
@color-btn-primary-focus-hover-border: @btn-primary-border;
@color-btn-primary-active-fg: #fff;
@color-btn-primary-active-bg: darken(@btn-primary-bg, 20%);

.btn-primary {
    .tb-button-variant(@color-btn-primary-fg; @color-btn-primary-bg; @color-btn-primary-border; @color-btn-primary-focus-hover-border; @color-btn-primary-active-fg; @color-btn-primary-active-bg);
}

@color-btn-green-fg: #fff;
@color-btn-green-bg: @bg-green;
@color-btn-green-border: @bg-green;
@color-btn-green-focus-hover-border: @bg-green;
@color-btn-green-active-fg: #fff;
@color-btn-green-active-bg: darken(@bg-green, 20%);

.btn-green {
    .tb-button-variant(@color-btn-green-fg; @color-btn-green-bg; @color-btn-green-border; @color-btn-green-focus-hover-border; @color-btn-green-active-fg; @color-btn-green-active-bg);
}

@color-btn-blue-fg: #fff;
@color-btn-blue-bg: @bg-blue;
@color-btn-blue-border: @bg-blue;
@color-btn-blue-focus-hover-border: @bg-blue;
@color-btn-blue-active-fg: #fff;
@color-btn-blue-active-bg: darken(@bg-blue, 20%);

.btn-blue {
    .tb-button-variant(@color-btn-blue-fg; @color-btn-blue-bg; @color-btn-blue-border; @color-btn-blue-focus-hover-border; @color-btn-blue-active-fg; @color-btn-blue-active-bg);
}

@import "staticstyle";
@import "navbar";
