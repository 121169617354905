// oswald-300 - latin
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: local('Oswald Light'), local('Oswald-Light'),
    url('../fonts/oswald/oswald-v10-latin-300.woff2') format('woff2'),
    url('../fonts/oswald/oswald-v10-latin-300.woff') format('woff');
}
// oswald-regular - latin
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: local('Oswald Regular'), local('Oswald-Regular'),
    url('../fonts/oswald/oswald-v10-latin-regular.woff2') format('woff2'),
    url('../fonts/oswald/oswald-v10-latin-regular.woff') format('woff');
}
// oswald-700 - latin
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: local('Oswald Bold'), local('Oswald-Bold'),
    url('../fonts/oswald/oswald-v10-latin-700.woff2') format('woff2'),
    url('../fonts/oswald/oswald-v10-latin-700.woff') format('woff');
}
